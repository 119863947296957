@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:wght@300&display=swap');


.bg-with-img {
    background-image: url('../../assets/banner/BANNER.jpg');
    background-size: cover;

    @media (max-width: 1200px) {
        background-image: url('../../assets/banner/movil.jpg');
        background-size: cover;
        height: 100%;

    }
}


.green-bg {
    background-color: rgba(0, 154, 78, 0.8);
}

.text {
    text-align: justify;
    line-height: 1.3 !important;
}

.center-align {
    place-self: center;
}

p {
    font-size: 1.2rem !important;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700 !important;
}

.title {
    color: #ffb800;
    font-family: 'Glober black', sans-serif;
    font-size: calc(1.2rem+2vw);
    font-weight: 900;
    text-transform: uppercase;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.81);
    text-align: center;

    @media (max-width: 440px ){
        font-size: 2rem ;
    }
}