@import "../../_global/variables.css";

footer {
    bottom: 0;
    width: 100%;
    background-color: var(--verde_starken);
    height: 100%;
}


.footer ul {
    font-size: 11px;
    line-height: 16px;
}
.footer ul li {
    padding: 0 24px 4px 0;
    display: inline-block;
}
.footer ul li a {
    color: rgb(6, 158, 41);
}
.footer ul li a:hover {
    color: rgb(33, 249, 0);
    text-decoration: underline;
}

.img {
    width: 23.4%;
}

.text {
    color: #FFFFFF;
    font-family: Bebas Neue ,sans-serif;
    font-weight: 500;
    padding: 0;
    margin: 0;
    line-height: 1;
}

h2 {
    font-size: 2rem;
}